import { ref } from 'vue'
import { defineStore } from 'pinia'
import { fetchSettings } from '@/api/settingsApi'
import { logSentryError } from '@/utils/tracking/sentry'

export const useGlobalSettingsStore = defineStore('globalSettings', () => {
  const isLoading = ref(false)
  const apiSiteSettings = ref(null)
  const hasUrlBeenReplaced = ref(false)
  const displayAlertMessage = ref(null)

  const isDeveloperModeFeatures = ref(import.meta.env.VITE_USE_DEVELOPER_FEATURES == 'true')

  const shouldUseDummyData = ref(import.meta.env.VITE_USE_DUMMY_DATA == 'true')

  const toggleLoading = (loadingState) => {
    isLoading.value = loadingState
  }

  const getSiteSettings = async () => {
    try {
      apiSiteSettings.value = await fetchSettings(import.meta.env.VITE_API_BASE_DOMAIN)
      if (displayAlertMessage.value === null || displayAlertMessage.value === undefined) {
        displayAlertMessage.value = apiSiteSettings.value?.shouldDisplayAlertMessage
      }
    } catch (error) {
      logSentryError(error)
      console.error(error)
      throw error
    }
  }

  const toggleHasUrlBeenReplaced = (hasBeenReplaced) => {
    hasUrlBeenReplaced.value = hasBeenReplaced
  }

  const toggleDisplayAlertMessage = (shouldDisplayAlertMessage) => {
    displayAlertMessage.value = shouldDisplayAlertMessage
  }

  return {
    isLoading,
    hasUrlBeenReplaced,
    isDeveloperModeFeatures,
    shouldUseDummyData,
    apiSiteSettings,
    displayAlertMessage,
    toggleLoading,
    getSiteSettings,
    toggleHasUrlBeenReplaced,
    toggleDisplayAlertMessage
  }
})
