// methods to track and identify users

import { v4 as uuidv4 } from 'uuid'
import { LS } from '@/helpers/local-storage-helpers'
import { LOCAL_STORAGE } from '@/config/local-storage'

// generate user identifyer
export const generateUserId = (userIp) => {
  return `${userIp}--${uuidv4()}`
}

// Check if user ID exists and rreturns it if it's present or null if not present
export const getUserGuid = () => {
  const userGuid = JSON.parse(LS.getItem(LOCAL_STORAGE.User))
  return userGuid?.guid
}

// Sets the user guid to local storage and returns the created value
export const setUserGuid = (userIp) => {
  const userGuid = generateUserId(userIp)
  const User = {
    guid: userGuid
  }
  LS.setItem(LOCAL_STORAGE.User, JSON.stringify(User))

  return userGuid
}
