// method to hit cloudflare and get ip address of user
// https://stackoverflow.com/questions/391979/how-to-get-clients-ip-address-using-javascript
export const ipGetter = async () => {
  const response = await fetch('https://www.cloudflare.com/cdn-cgi/trace')
  const data = await response.text()
  const ip = data
    .trim()
    .split('\n')
    .reduce(function (obj, pair) {
      pair = pair.split('=')
      return (obj[pair[0]] = pair[1]), obj
    }, {}).ip

  return ip
}
