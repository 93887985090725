<script setup>
import { computed } from 'vue'
import { useGlobalSettingsStore } from '@/stores/global-settings'
import { useSiteSettings } from '@/composables/useSiteSettings'

const globalSettings = useGlobalSettingsStore()
const siteSettings = useSiteSettings()

const isPwaInstalled = computed(() => siteSettings.isPwaInstalled)

const alertMessage = computed(() => globalSettings?.apiSiteSettings?.alertMessage)

const alertType = computed(() => {
  switch (globalSettings.apiSiteSettings.alertMessageType) {
    case 'Positive':
      return 'alert-success'
    case 'Infor':
      return 'alert-info'
    case 'Warning':
      return 'alert-warning'
    case 'Error':
      return 'alert-danger'
    default:
      return 'alert-info'
  }
})

const closeAlert = () => {
  globalSettings.displayAlertMessage = false
}
</script>

<template>
  <div class="alert-component" :class="{ pwa: isPwaInstalled }">
    <div class="alert" :class="alertType" role="alert">
      <button class="close-button" @click="closeAlert"><i class="fa-solid fa-x"></i></button>
      <div class="alert-component-content">
        <div v-if="alertMessage" class="cms-content">
          {{ alertMessage }}
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.alert-component {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
  &-content {
    max-width: 30rem;
  }
  .close-button {
    position: absolute;
    top: 2px;
    right: 2px;
    background: none;
    border: none;
    font-size: 0.8rem;
    cursor: pointer;
    color: black;
    border: 1px solid black;
    padding: 1px 5px;
  }
}
@media (max-width: 796px) {
  .alert-component {
    z-index: 1055;
    top: auto; /* Reset top position */
    right: auto; /* Reset right position */
    bottom: 20px; /* Position at the bottom */

    width: 100%;
    &.pwa {
      bottom: 6rem;
    }
  }
}
@media screen and (min-width: 800px) {
  .alert-component {
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust for centering */
  }
}
</style>
