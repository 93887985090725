export const scrollToTop = () => {
  window.scrollTo(0, 0)
}

export const scrollToBottom = () => {
  window.scrollTo(0, document.body.scrollHeight)
}

export const scrollToElementFromDropwodwnMenu = (elementId) => {
  const element = document.getElementById(elementId)
  if (element) {
    const dropdownMenu = document.querySelector('.dropdown-after')
    const isMenuOpen = dropdownMenu ? true : false
    const sectionTop = element.getBoundingClientRect().top
    const dropdownHeight = isMenuOpen ? dropdownMenu.offsetHeight : 0
    const scrollPosition = window.pageYOffset + sectionTop - dropdownHeight

    window.scrollTo({ top: scrollPosition, behavior: 'smooth' })
  }
}
// Scroll to section with an optional offset
export const scrollToSection = (queryElement, offset = 0) => {
  const section = document.querySelector(queryElement)
  if (section) {
    // Convert offset from rem to pixels if needed (assuming 1rem = 16px)
    const offsetPixels =
      typeof offset === 'string' && offset.endsWith('rem')
        ? parseFloat(offset) * 16 // Convert rem to pixels
        : offset

    // Calculate the position to scroll to, accounting for the offset
    const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset - offsetPixels

    // Scroll to the calculated position
    window.scrollTo({
      top: sectionPosition,
      behavior: 'smooth'
    })
  } else {
    console.warn('No element found for the specified selector:', queryElement)
  }
}

// Specifically used for carousel scrolling
export const scrollLeft = (element, scrollAmount) => {
  element.scrollBy({ left: scrollAmount, behavior: 'smooth' })
}

export const scrollRight = (element, scrollAmount) => {
  element.scrollBy({ left: scrollAmount, behavior: 'smooth' })
}

// NOTE: This needs to be placed on the element that has the overflow-y property set for scrolling
export const scrollSpecificElementByIdToTop = (elementId) => {
  const scrollableContainer = document.getElementById(elementId)
  if (scrollableContainer) {
    scrollableContainer.scrollTo({ top: 0, behavior: 'smooth' })
  }
}
