import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

export const useUserSettingsStore = defineStore(
  'user-settings',
  () => {
    const userSettings = ref({
      announcements: {
        lastReadAnnouncementDate: null,
        hasReadLatestAnnouncement: false
      }
    })

    const markAnnouncementAsRead = (lastAnnouncementUpdatedDate) => {
      userSettings.value.announcements.hasReadLatestAnnouncement = true
      userSettings.value.announcements.lastReadAnnouncementDate = lastAnnouncementUpdatedDate
    }

    const isLatestAnnouncementRead = (announcementDate) => {
      if (
        userSettings.value.announcements.lastReadAnnouncementDate &&
        announcementDate == userSettings.value.announcements.lastReadAnnouncementDate
      ) {
        return true
      } else {
        return false
      }
    }

    const validateAnnouncementDate = (announcementDate) => {
      if (!isLatestAnnouncementRead(announcementDate)) {
        userSettings.value.announcements.hasReadLatestAnnouncement = false
      }
    }

    const hasNewAnnouncements = computed(() => {
      return !userSettings.value.announcements.hasReadLatestAnnouncement
    })

    return {
      userSettings,
      hasNewAnnouncements,
      validateAnnouncementDate,
      markAnnouncementAsRead
    }
  },
  {
    persist: true
  }
)
