<script setup>
import { onMounted, computed, onUnmounted, onBeforeUnmount } from 'vue'
import { ipGetter } from '@/utils/tracking/ip-tracker'
import { getUserGuid, setUserGuid } from '@/utils/tracking/user-identify'
import { analyticsIdentify } from '@/utils/segment/segment.service'
import { scrollToTop } from '@/helpers/scroll'
import { useGlobalSettingsStore } from '@/stores/global-settings'
import { useNotificationsComposable } from '@/composables/useNotificationsComposable'

import AlertComponent from './components/alert-component.vue'

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  jsonData: {
    type: Object,
    default: () => ({})
  }
})

const globalSettings = useGlobalSettingsStore()
const notifications = useNotificationsComposable()

const showAlertNotification = computed(() => globalSettings?.displayAlertMessage)

const isLoading = computed(() => globalSettings?.isLoading)

onMounted(async () => {
  // get stored user guid
  let userGuid = getUserGuid()

  // if there's no user guid, generate one
  if (!userGuid) {
    // get user IP address
    const userIp = await ipGetter()
    // generate user guid
    userGuid = setUserGuid(userIp)
  }

  // update analytics identify for current user
  analyticsIdentify(userGuid, window)
  scrollToTop()
  notifications.setupServiceWorkerListener()
})

onUnmounted(() => {
  notifications.cleanupServiceWorkerListener()
})
</script>

<template>
  <alert-component v-if="showAlertNotification && !isLoading" />
  <router-view :component-data="jsonData" />
</template>

<style scoped>
.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.vue:hover {
  filter: drop-shadow(0 0 2em #42b883aa);
}
</style>
