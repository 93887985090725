// manages the page views from route changes
export const analyticsPage = (routePath, window) => {
  window.analytics?.page(routePath)
}

// wanting to be tracking specific actions
export const analyticsTrack = (trackingData, window) => {
  window.analytics?.track(trackingData.subject, trackingData.content)
}

// Identify the user based on a value
export const analyticsIdentify = (userIdByIp, window) => {
  if (userIdByIp) {
    window.analytics?.identify(userIdByIp)
  }
}
