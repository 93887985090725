// Local Storage methods
export const LS = {
  getItem(label) {
    return window.localStorage.getItem(label)
  },
  setItem(label, value) {
    return window.localStorage.setItem(label, value)
  },
  removeItem(label) {
    window.localStorage.removeItem(label)
  }
}
