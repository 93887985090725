// Helpers related to mobile device management on the browsers

export const isMobileDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  // Check for iPad on iOS 13 and later
  if (/iPad|Macintosh/i.test(userAgent) && 'ontouchend' in document) {
    return true
  }

  return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS|Fennec|Windows Phone|Silk|Kindle|Silk-Accelerated|hp-tablet|Tablet|PlayBook|BB10|MeeGo|Nokia|Symbian|Palm|ZuneWP7|Tizen|Bada|UCWEB|Blazer|Dolfin|Dolphin|Skyfire|Zune/i.test(
    userAgent
  )
}

export const getOrientation = (window) => {
  if (window.matchMedia('(orientation: portrait)').matches) {
    return 'portrait'
  } else if (window.matchMedia('(orientation: landscape)').matches) {
    return 'landscape'
  } else {
    return 'unknown'
  }
}

export const isPortrait = (window) => {
  return getOrientation(window) === 'portrait'
}

export const isLandscape = (window) => {
  return getOrientation(window) === 'landscape'
}

export const isPwaInstalled = (window) => {
  return (
    window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true
  )
}

export const isIos = (window) => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
}
