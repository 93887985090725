// This allows an element to have a swipe directive attached to it
// and a trigger that links to the swipe actions
// It defaults to 50 if not provided (sensitivity = 50)
// can pass through isActive to check if the device is mobile as we only want this to run on specific situation
// can pass through stopPropagation to stop the event from bubbling up the dom tree
/*
<template>
  <div v-swipe-trigger="{ direction: 'right', callback: handleSwipeRight, sensitivity: 100, isActive: true, stopPropagation: true }" class="swipeable-element">
    Swipe right to close me!
  </div>
</template>
*/

export const swipeTriggerDirective = {
  beforeMount(el, binding) {
    const {
      isActive,
      direction,
      callback,
      sensitivity = 50,
      stopPropagation = false
    } = binding.value

    // in some situations we want the active to be false and not run the directive
    // but still attached to the element so it can be dynamically changed
    if (!isActive) {
      return
    }

    let touchstartX = 0
    let touchendX = 0
    let touchstartY = 0
    let touchendY = 0

    const handleGesture = () => {
      const horizontalSwipe = touchendX - touchstartX
      const verticalSwipe = touchendY - touchstartY

      if (direction === 'right' && horizontalSwipe > sensitivity) {
        callback()
      } else if (direction === 'left' && horizontalSwipe < -sensitivity) {
        callback()
      } else if (direction === 'up' && verticalSwipe < -sensitivity) {
        callback()
      } else if (direction === 'down' && verticalSwipe > sensitivity) {
        callback()
      }
    }

    const touchstartHandler = (e) => {
      if (stopPropagation) e.stopPropagation()
      touchstartX = e.changedTouches[0].screenX
      touchstartY = e.changedTouches[0].screenY
    }

    const touchendHandler = (e) => {
      if (stopPropagation) e.stopPropagation()
      touchendX = e.changedTouches[0].screenX
      touchendY = e.changedTouches[0].screenY
      handleGesture()
    }

    el.__vueTouchstartHandler__ = touchstartHandler
    el.__vueTouchendHandler__ = touchendHandler

    el.addEventListener('touchstart', touchstartHandler)
    el.addEventListener('touchend', touchendHandler)
  },
  unmounted(el) {
    el.removeEventListener('touchstart', el.__vueTouchstartHandler__)
    el.removeEventListener('touchend', el.__vueTouchendHandler__)
    delete el.__vueTouchstartHandler__
    delete el.__vueTouchendHandler__
  }
}
