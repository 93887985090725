import { logSentryError } from '@/utils/tracking/sentry'

export const fetchSettings = async (domain) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const url = `https://${domain}/api/service/creature-creator/site-settings`
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error('No settings found')
    }
    return await response.json()
  } catch (error) {
    logSentryError(error)
    throw error
  }
}
