import { onMounted, onUnmounted, ref } from 'vue'
import { Tooltip } from 'bootstrap'

export const useTooltip = () => {
  let tooltipList = ref([])

  onMounted(() => {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    )
    tooltipList.value = tooltipTriggerList.map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl))
  })

  onUnmounted(() => {
    tooltipList.value.forEach((tooltip) => tooltip.dispose())
  })

  return { tooltipList }
}

export const tooltipDirective = {
  mounted(el, binding) {
    createTooltip(el, binding)
  },
  updated(el, binding) {
    if (binding.value !== binding.oldValue) {
      // Dispose of the existing tooltip to prevent memory leaks
      const tooltipInstance = Tooltip.getInstance(el)
      if (tooltipInstance) {
        tooltipInstance.dispose()
      }
      // Create a new tooltip with the updated title
      createTooltip(el, binding)
    }
  },
  beforeUnmount(el) {
    // Ensure to clean up the tooltip instance when the element is unmounted
    const tooltipInstance = Tooltip.getInstance(el)
    if (tooltipInstance) {
      tooltipInstance.dispose()
    }
  }
}

function createTooltip(el, binding) {
  let placement = 'top' // Default placement
  // Check if a placement argument is provided or use modifiers
  if (binding.arg) {
    placement = binding.arg
  } else if (binding.modifiers.top) placement = 'top'
  else if (binding.modifiers.bottom) placement = 'bottom'
  else if (binding.modifiers.left) placement = 'left'
  else if (binding.modifiers.right) placement = 'right'

  if (binding.value) {
    el.setAttribute('title', binding.value)
    el.setAttribute('data-bs-toggle', 'tooltip')
    // Initialize the tooltip with the specified placement
    new Tooltip(el, {
      placement: placement
    })
  }
}
